import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
// import * as anime from 'animejs';
import anime from '../../../node_modules/animejs/lib/anime.js';

@Component({
  selector: 'flink-preloader',
  templateUrl: './flink-preloader.component.html',
  styleUrls: ['./flink-preloader.component.scss']
})
export class FlinkPreloaderComponent implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit() {

  }
  
  ngAfterViewInit() {
    const duration = 1400;
    const delay = 300;
    const offset = .05;

    anime.timeline({
      loop: true
    }).add({
      targets: '.preloader .circle',
      translateX: [
        { value: 250, duration: duration, delay: delay },
        { value: 0, duration: duration, delay: delay }
      ],
      scaleX: [
        { value: 3, duration: duration * offset, delay: delay, easing: 'easeOutExpo' },
        { value: 1, duration: duration * (1 - offset) },
        { value: 3, duration: duration * offset, delay: delay, easing: 'easeOutExpo' },
        { value: 1, duration: duration * (1 - offset) }
      ],
      easing: 'easeOutElastic(1, .8)',
    }).add({
      targets: '.preloader .shadow',
      translateX: [
        { value: 250, duration: duration, delay: delay },
        { value: 0, duration: duration, delay: delay }
      ],
      scaleX: [
        { value: 3, duration: duration * offset, delay: delay, easing: 'easeOutExpo' },
        { value: 1, duration: duration * (1 - offset) },
        { value: 3, duration: duration * offset, delay: delay, easing: 'easeOutExpo' },
        { value: 1, duration: duration * (1 - offset) }
      ],
      easing: 'easeOutElastic(1, .8)',
    }, 100);

    // this.basicTimeline.add({
    //   targets: '.circle',
    //   duration: duration,
    //   translateX: '10rem',
    //   easings: 'linear',
    // }).add({
    //   targets: '.circle',
    //   duration: duration,
    //   translateX: '0rem',
    //   easings: 'linear'
    // });
    // this.basicTimeline.add({
    //   targets: '.circle',
    //   duration: 2000,
    //   width: 300,
    //   easing: 'linear'
    // }).add({
    //   targets: '.circle',
    //   width: 80,
    //   height: 80,
    //   // delay: 500,
    //   duration: 750,
    //   borderRadius: 80,
    //   backgroundColor: '#71DFBE'
    // });
  }

}

import {Component} from '@angular/core';
import {WSMessage, WsService} from './ws.service';
import {Subject} from 'rxjs';

export interface Message {
  author: string;
  message: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'flink-tests';
  private ws: Subject<MessageEvent>;

  responses: Array<string> = [];

  constructor(private wsService: WsService) {
    // this.ws = wsService.connect('ws://localhost:4001/ws');
    // this.ws.subscribe({
    //   next: (v: MessageEvent) => {
    //     this.responses.push(v.data);
    //   },
    //   error: (e) => console.error(e)
    // });
  }

  register() {
    this.ws.next(<MessageEvent>{data: 'Register'});
  }

  send() {
    this.ws.next();
  }

}
